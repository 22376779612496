@import "variables";
// includes
@import "includes/functions";
@import "includes/normalize";
@import "includes/45p";
@import "includes/fonts";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
}

body {
    background-color: $black;
    color: $white;
    font-family: $primary-font;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
    background-image: linear-gradient(to bottom, transparent, #000000AA),url('../img/pink-bg-wide.jpg');
    background-position: bottom center, 50% 20%;
    background-size: 100% 50%, cover;
    background-repeat: no-repeat;
    
    @media screen and (max-width: 1000px){
        background-image: linear-gradient(to bottom, transparent, #000000AA),url('../img/pink-bg.jpg');
    }
    
    @media screen and (max-width: 500px){
        background-size: 100% 60%, 200% auto;
        background-position: bottom center, 68% 0;
        background-image: linear-gradient(to bottom, transparent 0%, #51779a 30%), url('../img/pink-bg.jpg')
    }
}

a{
    color: inherit;
    @include hover{
        color: $primary-color;
    }
}

.page-wrap{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

#header{
    flex: 1;
    .container{
        max-width: rem(1400);
    }
    h1{
        display: flex;
        justify-content: space-between;
        div{
            width: rem(300);
            &:first-child{
                width: rem(260);
            }
        }
    }
    @media screen and (max-width: 500px){
        padding-top: 5vw;
        margin-bottom: 80vw;
    }
    
}

#main{
    .container{
        max-width: rem(1000);
        text-align: center;
    }
}

img.mobile{
    width: rem(280);
}

.date{
    font-size: rem(45);
    text-align: center;
    margin: rem(-20) auto rem(5);
}

.presaves{
    width: 100%;
    max-width: rem(1000);
    text-align: center;
    a{
        display: inline-block;
        width: rem(280);
        max-width: 100%;
        height: rem(46);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: $white;
        vertical-align: middle;
        margin: rem(9);
        color: $primary-color;
        background-color: $white;
        border-radius: rem(30);
        @media screen and (max-width: 500px){
            margin: rem(9) 0;
        }

        img{
            height: rem(20);
            width: auto;
            margin-left: rem(5);
            transition: height .3s;
        }
        span{
            display: block;
            font-size: rem(40);
            font-weight: normal;
        }
        @include hover{
            background-color: $primary-color;
            color: $white;
            img{
                filter: brightness(100);
            }
        }
        &:nth-child(1){
            img{
                height: rem(26);
            }
        }
        &:nth-child(3){
            img{
                height: rem(18);
            }
        }
        &:nth-child(4){
            img{
                height: rem(24);
                padding-top: rem(4);
            }
        }
        &:nth-child(5){
            img{
                height: rem(16);
            }
        }
    }
}

.note{
    font-family: sans-serif;
    font-size: rem(8);
    text-transform: uppercase;
    letter-spacing: .1em;
    text-align: center;
}

#footer{
    text-align: center;
    .social{
        font-size: rem(18);
        
        @media screen and (max-width: 500px){
            font-size: rem(16);
        }
        li{
            display: inline-block;
            margin: .5em;
        }
    }

    .copyright{
        font-family: sans-serif;
        font-size: rem(8);
        text-transform: uppercase;
        letter-spacing: .1em;
    }
}

@media screen and (max-width: 500px){
    .desktop{
        display: none;
    }
}
@media screen and (min-width: 501px){
    .mobile{
        display: none;
    }
}


.popup{
    color: $primary-color;
    background-color: $white;
    background-size: cover;
    margin: rem(20) auto;
    padding: rem(30);
    width: 100%;
    max-width: rem(500);
    text-align: center;
    position: relative;
    font-size: rem(16);
    font-weight: 900;
    .mfp-close{
        color: $primary-color;
    }

    #apple_music_form{
        margin-left: rem(15);
        display: inline-block;
        vertical-align: middle;
        font-size: rem(30);
        letter-spacing: .05em;
        @media screen and (max-width: 520px){
            margin: rem(25) auto 0;
            display: block;
        }
        input{
            border-bottom: rem(2) solid $primary-color;
            text-align: center;
            color: $primary-color;
            
            font-family: sans-serif;
            font-size: rem(18);
            transition: .3s all;
            padding: rem(6);
            &::placeholder{
                color: $primary-color;
                line-height: 1;
                text-transform: uppercase;
            }
        }
        p{
            margin: .5em auto;
        }

        .btn{
            border: rem(2) solid $primary-color;
            color: $primary-color;
            font-size: rem(30);
            text-transform: uppercase;
            @include hover{
                color: $white;
                background-color: $primary-color;
            }
        }
    }
}