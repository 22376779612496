//settings
$fa-font-path: "../font";

// Colors //
$black: #000;
$white: #eaf1e8;
$primary-color: #51779a;
$gray: #656565;

//Fonts
$primary-font: "Backlash Script W05 Regular", sans-serif;
